/* jshint esversion: 6 */

// ###############################################################
// TABLE ENHANCEMENTS
// ###############################################################

// Provides support for clickable rows anywhere on that row, which will trigger the
// first link
$('.table-clickable > tbody > tr').click(function () {
  const href = $(this).find("a").attr('href');

  if (href) {
    window.location = href;
  }
});

// ###############################################################
// ERROR NOTIFICATIONS
// ###############################################################

// This workaround allows us to add Bootstrap's 'has-error' class to the
// form-group div when we place an extra marker called 'error' on the input
$('div.field_with_errors input.form-control.error-wrapper, div.field_with_errors input.form-control.error-wrapper, div.field_with_errors select.form-control.error-wrapper, div.field_with_errors textarea.form-control.error-wrapper').parent().parent().parent().addClass('has-error');
$('div.input-group div.field_with_errors input.form-control.error-wrapper').parent().parent().parent().parent().addClass('has-error');


// ###############################################################
// JQUERY
// ###############################################################

// Global/common AJAX setup
$.ajaxSetup({
  xhrFields: { withCredentials: true },
  dataType: 'json',
  cache: false,
  accepts: 'application/json',
  beforeSend: function (xhr) {
    xhr.setRequestHeader('Accept', 'text/javascript');
  }
});

// ###############################################################
// LOCALE AND NUMBERING SUPPORT
// ###############################################################

// Default locale for application
const locale = 'en_us';

// convert locale to a locale code supported by JS;
// language code list => http://www.science.co.il/Language/Locale-codes.php
function jsLocale(locale) {
  let jsLocale = 'en'; // set default JS locale

  switch (locale) {
    case 'en_ca':
      jsLocale = 'en-ca';
      break;
    case 'en_us':
      jsLocale = 'en-us';
      break;
    case 'id':
      jsLocale = 'id';
      break;
  }

  return jsLocale;
}

// Export to global scope because Webpack
window.jsLocale = jsLocale;

String.prototype.toFormattedLocaleString = function (locale, decimalDigits) {
  decimalDigits = decimalDigits || 0;

  let parsedValue = parseFloat(this);

  return parsedValue.toLocaleString(jsLocale(locale), {
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits
  });
};

Number.prototype.toFormattedLocaleString = function (locale, decimalDigits) {
  decimalDigits = decimalDigits || 0;

  let parsedValue = parseFloat(this);

  return parsedValue.toLocaleString(jsLocale(locale), {
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits
  });
};

// ###############################################################
// BOOTSTRAP-DATETIMEPICKER
// ###############################################################

// Override default behaviors such as icons
$.fn.datetimepicker.defaults.icons = {
  time: 'fal fa-clock',
  date: 'fal fa-calendar',
  up: 'fal fa-plus',
  down: 'fal fa-minus',
  next: 'fal fa-chevron-right',
  previous: 'fal fa-chevron-left'
};

$.fn.datetimepicker.defaults.keyBinds = {
  up: null,
  down: null,
  left: null,
  right: null,
  t: null,
  'delete': null
};

// ###############################################################
// MISC UTILITIES
// ###############################################################

// Used where we're dynamically adding a table row for Catch or other goodies
function generateUUID() {
  let d = new Date().getTime();

  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    // use high-precision timer if available
    d += performance.now();
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);

    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

// Export to global scope because Webpack
window.generateUUID = generateUUID;

// ###############################################################
// ACCORDION MENUS (ANALYTICS)
// ###############################################################

const accordionMenu = $('.accordion-menu');

accordionMenu.on('show.bs.collapse', function () {
  $('.accordion-menu .in').collapse('hide');
});

function toggleAccordionChevron(e) {
  $(e.target)
    .siblings('.menu-icon')
    .find('i.fal')
    .toggleClass('fa-chevron-down fa-chevron-right');
}

accordionMenu
  .on('hide.bs.collapse', toggleAccordionChevron);

accordionMenu
  .on('show.bs.collapse', toggleAccordionChevron);
