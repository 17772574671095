/* eslint no-console:0 */

/*
 * Include in this file references to any libraries or general application specific code that's
 * applicable to multiple areas of the application.
 *
 * Examples would be date time pickers, standard controls, bootstrap js customizations, etc.
 *
 * jQuery is automatically picked up here due to bootstrap requirements
 */

// Libraries
// From vendor/assets/javascript
import 'management/bootstrap.min';
import 'management/bootstrap-datetimepicker';
import 'management/selectize';

// Application specific
import '../../js/management/common.es6'
